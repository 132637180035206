import React from 'react'
import Layout from '../components/layout'

const NotFoundPage = () => (
  <Layout>
    <section className="header style1">
      <div className="inner padding-extra">
        <h1>NOT FOUND</h1>
        <p>You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
